import { DateIcon, FileIcon } from '@assets/icons';
import MovingToFromSummary from '@containers/Consignments/components/MovingToFromSummary';
import { ICON_COLORS } from '@containers/Consignments/DeviceTransferHelper';
import { getFormAlias } from '@utils/form-alias';
import React from 'react';

import { MovementToFromSectionProps } from './DevicesMovementToFromSection';

const MovementReceiptToFromSection: React.FC<MovementToFromSectionProps> = ({ consignment }) => {
    const transferDate = consignment?.deviceMovementDate
        ? new Date(consignment.deviceMovementDate).toMLADateString?.() || ''
        : '';

    const Program = consignment?.forms?.find(form => form?.type?.startsWith("LPA") || form?.type?.startsWith("EU"));
    const formAlias = getFormAlias(Program?.type || '');
    return (
        <>
            <style jsx>
                {`
                    @import 'mixins';
                    @import 'vars';
                    @import 'layout';

                    .summary-panel {
                        flex: 2 0 65%;

                        .date-info {
                            gap: 10px;
                        }

                        .to-details {
                            border-left: 1px solid $grey-border;
                            position: relative;
                        }
                    }

                    .box {
                        box-sizing: border-box;
                        background-color: $color-white;
                        border: 1px solid $grey-border;
                        border-radius: 4px;
                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 80%);
                        }
                    }

                    .grey-text {
                        color: $color-subtitle;
                        cursor: inherit;
                    }

                    .horizontal-line {
                        border-top: 1px solid $color-border; /* Creates a black 1px wide line */
                        margin: 15px 0; /* Optional: Add some spacing around the line */
                    }

                    .bold {
                        font-weight: 600;
                        margin: 0;
                    }

                    .FormsSection--Forms {
                        gap: 10px;
                        flex-wrap: wrap;
                    }

                    :global(.Documents--img) {
                        width: 18px;
                        max-height: 22px;
                    }
                `}
            </style>
            <div>
                <div className="summary-panel box  m-v-32">
                    <div className="panel-heading flex-row flex-between p-v-16 p-h-16">
                        <h2>Transfer Summary</h2>
                        <div className="date-info flex-center-row flex end ">
                            {formAlias && <div className="FormsSection--Forms flex-row">
                                <label
                                    htmlFor=""
                                    className="info-label grey-text"
                                >
                                    Serial Number
                                </label>
                                <div
                                    key={formAlias.program}
                                    className="flex-center-row"
                                >
                                    <img
                                        src={formAlias.image}
                                        className="Documents--img  m-r-4"
                                        alt="Logo"
                                    />
                                    <p className="bold m-r-40">
                                        {formAlias.alias} {Program?.serialNumber && <span>({Program?.serialNumber})</span>}
                                    </p>
                                </div>
                            </div>}
                            <FileIcon color={ICON_COLORS.GREY} />
                            <label
                                htmlFor=""
                                className="info-label grey-text"
                            >
                                Upload ID
                            </label>
                            <p className="bold m-r-40">{consignment?.deviceMovementID}</p>

                            <DateIcon color={ICON_COLORS.GREY} />
                            <label
                                htmlFor=""
                                className="info-label grey-text"
                            >
                                Transfer Date
                            </label>
                            <p className="bold">{transferDate}</p>
                        </div>
                    </div>
                    <div className="p-h-16 p-v-16">
                        <div className="horizontal-line" />
                    </div>

                    <MovingToFromSummary consignment={consignment} />
                </div>
            </div>
        </>
    );
};

export default MovementReceiptToFromSection;
