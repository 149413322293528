import axios from 'axios';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import Config from '../config';

export const USER_TOKEN_KEY = '_tokenkey';
export const USER_CLAIMS_KEY = '_claims';
export const X_ENVD = '_xenvd';

const fetchQuery = async (operation: { text: any }, variables: any) => {
    const query = await axios.post(Config.BASE_GRAPHQL_SERVER_URL + "/graphql", {
        query: operation.text,
        variables,
    });
    return query.data;
};

const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});

export const createEnvironment = () => {
    return new Environment({
        network: Network.create(fetchQuery),
        store: new Store(new RecordSource()),
    });
};

export default environment;
