import { MovementActions } from "@common/context/MovementContext";
import { ConsignmentDetailQueryResponse } from "@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql";
import React from 'react';

interface MessageConfig {
    condition: boolean;
    message: string | JSX.Element;
}

const FooterMessages: React.FC<{
    validatedDevices: { totalDevices: number };
    actions: MovementActions;
    consignment?: ConsignmentDetailQueryResponse['consignment'];
}> = ({ validatedDevices, actions, consignment }) => {
    // Safe date formatting with type checking
    const formatDate = (date: unknown): string => {
        if (!date) return '';

        try {
            return new Date(date as string).toMLADateString();
        } catch (error) {
            console.error('Invalid date format:', error);
            return '';
        }
    };

    // Define message configurations
    const messages: MessageConfig[] = [
        {
            condition: Boolean(actions?.canPreviewMovement),
            message: (
                <>
                    NLIS movement available on <b>{formatDate(consignment?.movementDate)}</b>
                </>
            )
        },
        {
            condition: Boolean(actions?.isReceiver && actions?.canPerformMovement),
            message: "You can move onto your PIC the provided list, or if you disagree, upload your own tag list by clicking 'Remove & replace devices'."
        },
        {
            condition: Boolean(actions?.isReceiver && !actions?.isAccountAuthorizedForMovement && actions?.canViewMovement),
            message: "You can not complete NLIS movement without a NLIS account."
        },
        {
            condition: Boolean(!actions?.isReceiver && actions?.canViewMovement),
            message: (
                <>
                    Edits are no longer available as the movement date of{' '}
                    <b>{formatDate(consignment?.movementDate)}</b> has passed.
                </>
            )
        }
    ];
    if (validatedDevices.totalDevices === 0) return null;

    return (
        <>
            {messages.map((config, index) => (
                config.condition && (
                    <div
                        key={index}
                        className="m-t-16 flex-end-row flex-end"
                        style={{ width: '100%' }}
                    >
                        <h5>{config.message}</h5>
                    </div>
                )
            ))}
        </>
    );
};

export default FooterMessages;