import Config from '@config';
import React from 'react';

const NLISLoginLink: React.FC = () => (
    <a
        href={Config.NLIS_URL}
        target="_blank"
        referrerPolicy="no-referrer"
        rel="noreferrer"
    >
        NLIS
    </a>
);

export default NLISLoginLink;
