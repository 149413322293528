import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';

import { DeviceMovementPropertyToPropertyInput } from './__generated__/deviceMovementPropertyToPropertyMutation.graphql';

export const deviceMovementPropertyToPropertyMutation = graphql`
    mutation deviceMovementPropertyToPropertyMutation(
        $envdAccountId: String!
        $input: DeviceMovementPropertyToPropertyInput!
    ) {
        deviceMovementPropertyToProperty(envdAccountId: $envdAccountId, input: $input) {
            deviceMovementID
        }
    }
`;

const commitAsync = async (
    mutate: any,
    inputData: DeviceMovementPropertyToPropertyInput,
    envdAccountId: String
): Promise<Partial<any | undefined>> => {
    MLALogger.Log(['deviceMovementPropertyToPropertyMutation', 'commitAsync', 'Sending'], { inputData });
    try {
        const data = await mutate({
            variables: { input: inputData, envdAccountId },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['deviceMovementPropertyToPropertyMutation', 'commitAsync'], { response, error });
                if (error) {
                    MLALogger.Log(['deviceMovementPropertyToPropertyMutation', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['deviceMovementPropertyToPropertyMutation', 'commitAsync'], {
                        response,
                        data: response.deviceMovementPropertyToProperty.deviceMovementID,
                        request: inputData,
                    });
                }
            },
        });
        return data;
    } catch (error) {
        MLALogger.Log(['deviceMovementPropertyToPropertyMutation'], error, 'error');
        throw error;
    }
};

export default commitAsync;
