export enum SectionName {
    MOVEMENT = 'movement',
    TEMPLATE = 'template',
    FORMS = 'forms',
    LIVESTOCK_DESCRIPTION = 'description',
    LIVESTOCK_HISTORY = 'history',
    FOOD_SAFETY = 'food',
    CHEMICAL_AND_TREAMENT_HISTORY = 'chemical',
    DECLARATION = 'declaration',
    TRANSPORTER = 'transporter',
    ADDITIONAL_INFO = 'additional',
}

export enum ConsignmentSpecies {
    GOAT = 'GOAT',
    BOBBY_CALVES = 'BOBBY_CALVES',
    CATTLE = 'CATTLE',
    SHEEP_LAMB = 'SHEEP_LAMB',
}

/** Available account types */
export enum AccountTypeEnum {
    LPA = 'LPA',
    NLIS = 'NLIS',
    TRANSPORTER = 'TRANSPORTER',
    AUTHVIEWER = 'AUTHVIEWER',
    BUYER = 'BUYER',
    ADMIN = 'ADMIN',
}

/** Available nlis account types */
export enum NlisAccountTypeEnum {
    AGENT = 'AGENT',
    PROCESSOR = 'PROC',
    SALEYARD = 'SALEYARD',
    PRODUCER = 'PROD',
    FEEDLOT = 'FEEDLOT',
    // The following are elevated viewer
    AUDIT = 'AUDIT',
    POLICE = 'POLICE',
    SA = 'SA',
    NRS = 'NRS',
    SDA = 'SDA',
    SDALIGHT = 'SDALIGHT',
    SDAMEDIUM = 'SDAMEDIUM',
}

/** Accreditations for LPA accounts */
export enum AccreditationCodeEnum {
    LPA = 'lPA',
    EU = 'eU',
    MSA = 'mSA',
    EUGFHQB = 'eUGFHQB',
    NFAS = 'nFAS',
    HRG = 'hRG',
}

/** Form name display for user */
export enum FormNameDisplayEnum {
    NVD = 'NVD',
    EUNVD = 'EU NVD',
    NFASDD = 'NFAS DD',
    NFASFormB = 'NFAS Form B',
    EUGFHQB = 'NFAS EU HQB',
    MSA = 'MSA',
    HD = 'HD',
    CattleHD = 'NCHD',
    SheepHD = 'NSHD',
    GoatHD = 'NGHD',
    HRG = 'HRG',
}
/** Available role types */
export enum RoleTypeEnum {
    TRANSPORTER = 'TRANSPORTER',
    AUTHVIEWER = 'AUTHVIEWER',
    PRODUCER = 'PRODUCER',
    ELEVATEDVIEWER = 'ELEVATEDVIEWER',
    RECEIVER = 'RECEIVER',
    FACILITATOR = 'FACILITATOR',

    // New role for expired LPA account
    EXPIRED = 'EXPIRED',

    // New role which will be introduced in Buyer Persona
    BUYER = 'BUYER',
}

/** Accreditation expiry status for LPA accounts */
export enum AccreditationStatusEnum {
    VALID = 'valid',
    WITHIN_2MONTHS = 'within2Months',
    INVALID = 'invalid',
    // LPA status is true but Reaccreditation date is passed the current date(ENVDW-1230)
    // Introduced the below status since the access is there but it is expired in terms of Reaccreditation date
    EXPIRED_ACCESS = 'expiredAccess',
}

/** Accreditation expiry pop up timing for LPA accounts */
export enum AccreditationExpiryStatusTimeEnum {
    WITHIN_2MONTHS = 60,
}

export enum CommenterRole {
    producer = 'Vendor',
    receiver = 'Receiver',
    authViewer = 'Viewer',
    transporter = 'Transporter',
}

export enum TransporterQuestionId {
    fullname = '158',
    rego = '159',
    phone = '160',
    signature = '161',
    date = '162',
    time = '163',
    declaration = '164',
    email = '182',
}

export enum DeviceValidationStatus {
    VALIDATED = 'VALIDATED',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
}

export enum DeviceResponseStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    NOT_FOUND = 'NOT_FOUND',
}

export enum ValidationApiAction {
    ADD = 'ADD',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
}

// Enum for warning messages
export enum DeviceWarningMessage {
    SALEYARD = 'THIS IS A SALEYARD PIC',
    DECEASED = 'DIED ON PIC',
    NO_MATCH = 'NOT SPECIFY PIC',
    WRONG_SPECIES = 'WRONG SPECIES',
}
