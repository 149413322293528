import { ValidatedDevice } from '@common/context/DevicesContext';
import { getStatusInfo } from '@containers/Consignments/DeviceTransferHelper';
import ValidationCard from '@containers/Consignments/ValidationCard';
import { DeviceValidationStatus } from '@utils/enums';
import React from 'react';

interface ValidationBannersProps {
    devicesByStatus: {
        warnings: ValidatedDevice[];
        errors: ValidatedDevice[];
        validated: ValidatedDevice[];
    };
}

const ValidationBanner: React.FC<ValidationBannersProps> = ({ devicesByStatus }) => {
    const { warnings, errors } = devicesByStatus;
    const warning = getStatusInfo({ status: DeviceValidationStatus.WARNING, issueCount: warnings.length });
    const error = getStatusInfo({ status: DeviceValidationStatus.ERROR, issueCount: errors.length });
    const valid = getStatusInfo({ status: DeviceValidationStatus.VALIDATED });

    if (warnings.length === 0 && errors.length === 0) {
        return (
            <ValidationCard
                title={valid.title}
                icon={valid.icon}
                tooltip={valid.tooltip}
                description={valid.description}
                status={DeviceValidationStatus.VALIDATED}
            />
        );
    }

    return (
        <>
            {errors.length > 0 && (
                <ValidationCard
                    status={DeviceValidationStatus.ERROR}
                    title={error.title}
                    icon={error.icon}
                    tooltip={error.tooltip}
                    description={error.description}
                />
            )}
            {warnings.length > 0 && (
                <ValidationCard
                    status={DeviceValidationStatus.WARNING}
                    title={warning.title}
                    icon={warning.icon}
                    tooltip={warning.tooltip}
                    description={warning.description}
                />
            )}
        </>
    );
};

export default ValidationBanner;
