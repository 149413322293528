const FileSvg = ({...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            {...props}
        >
            <path
                d="M3.67 12.335h6.666V14H3.669zM3.67 9h6.666v1.667H3.669zm5-8.333H2.002c-.917 0-1.667.75-1.667 1.667v13.333c0 .917.742 1.667 1.658 1.667h10.009c.916 0 1.666-.75 1.666-1.667v-10zm3.333 15h-10V2.335h5.833V6.5h4.167z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FileSvg;
