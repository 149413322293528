import { ENVDConfig } from '@typings';

/*
    NOTE: Webpack caches the pre-cache build. So you will need to trigger a HMR in order to get the latest
          values from a dotenv file. E.g. Add a comment, save, then revert.
*/
const Config: ENVDConfig = {
    BASE_GRAPHQL_SERVER_URL: process.env.REACT_APP_BASE_GRAPHQL_SERVER_URL_V2 || 'https://api.dev.integritysystems.com.au/v2',
    //BASE_GRAPHQL_SERVER_URL: process.env.REACT_APP_BASE_GRAPHQL_SERVER_URL_V2 || 'https://localhost:5004/v2',
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || 'https://sso-s.mla.com.au',
    AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID || 'YgCpRtbK3tvrxrNqxEKfOcOohMWDjO8l',
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.uat.integritysystems.com.au/graphql',
    PAGINATION_PER_PAGE_LIMIT: (process.env.PAGINATION_PER_PAGE_LIMIT && Number.parseInt(process.env.PAGINATION_PER_PAGE_LIMIT, 10)) || 20,
    AUTO_SAVE_TIMEOUT: (process.env.AUTO_SAVE_TIMEOUT && Number.parseInt(process.env.AUTO_SAVE_TIMEOUT, 10)) || 10000,
    POLLING_INTERVAL: (process.env.POLLING_INTERVAL && Number.parseInt(process.env.POLLING_INTERVAL, 10)) || 20000,
    POLLING_URL: process.env.REACT_APP_POLLING_URL || 'https://offline.integritysystems.com.au',
    SERVICE_DESK_URL: process.env.REACT_APP_SERVICE_DESK_URL || 'https://www.integritysystems.com.au/envd-help',
    LPA_URL: process.env.REACT_APP_LPA_URL || 'https://lpa.nlis.com.au/',
    LPA_LOGIN_URL: process.env.REACT_APP_LPA_LOGIN_URL || 'https://testlpav4.nlis.com.au/Account/Login?ReturnUrl=http://localhost:3000/login',
    NLIS_URL: process.env.REACT_APP_NLIS_URL || 'https://leuat.nlis.com.au',
    FIRST_CONSIGNMENT_NUMBER: !!process.env.REACT_APP_FIRST_CONSIGNMENT_NUMBER ? parseInt(process.env.REACT_APP_FIRST_CONSIGNMENT_NUMBER, 10) : 100000000,

    FLAG_AUTOSAVE: Boolean(process.env.REACT_APP_FLAG_AUTOSAVE) || false,
    FLAG_OFFLINE_CAPABLE: Boolean(process.env.FLAG_OFFLINE_CAPABLE) || false,
    FLAG_DISABLE_LOGIN_UI: Boolean(process.env.REACT_APP_DISABLE_LOGIN_UI) || false,
    ISC_CUSTOMERFEEDBACK_URL: process.env.REACT_APP_CUSTOMER_FEEDBACK_URL || 'https://stest.oraclecms.com/feedback/external',
    MYMLA_BASE_URL: process.env.REACT_APP_MYMLA_BASE_URL || 'https://www-t.mla.com.au/mymla',
    MYMLA_TEST_USERNAME: process.env.REACT_APP_MYMLA_TEST_USERNAME || 'envdintegrationtestuser@integritysystems.com.au',
    MYMLA_TEST_PASSWORD: process.env.REACT_APP_MYMLA_TEST_PASSWORD || '',
};

if ('table' in console) {
    console.table(process.env);
    console.table(Config);
    // Another text pls ignore me
}

export default Config;
