import { graphql } from 'relay-hooks';

// eslint-disable-next-line
export const GetDeviceMovementStatusQuery = graphql`
    query DeviceMovementStatusQuery($consignmentNumber: String!, $envdAccountId: String!) {
        deviceMovementStatus(envdAccountId: $envdAccountId, consignmentNumber: $consignmentNumber) {
            movementStatus
        }
    }
`;
