import { DateIcon } from '@assets/icons';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import MovingToFromSummary from '@containers/Consignments/components/MovingToFromSummary';
import React from 'react';

export interface MovementToFromSectionProps {
    consignment: ConsignmentDetailQueryResponse['consignment'];
}

const DevicesMovementToFromSection: React.FC<MovementToFromSectionProps> = ({ consignment }) => {
    return (
        <>
            <style jsx>
                {`
                    @import 'mixins';
                    @import 'vars';
                    @import 'layout';

                    .summary-panel {
                        flex: 2 0 65%;

                        .date-info {
                            gap: 10px;
                        }

                        .to-details {
                            border-left: 1px solid $grey-border;
                            position: relative;
                        }
                    }

                    .box {
                        box-sizing: border-box;
                        background-color: $color-white;
                        border: 1px solid $grey-border;
                        border-radius: 4px;
                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 80%);
                        }
                    }

                    .grey-text {
                        color: $color-subtitle;
                        cursor: inherit;
                    }

                    .horizontal-line {
                        border-top: 1px solid $color-border; /* Creates a black 1px wide line */
                        margin: 15px 0; /* Optional: Add some spacing around the line */
                    }

                    .bold {
                        font-weight: 600;
                    }
                `}
            </style>
            <div>
                <div className="summary-panel box  m-v-32">
                    <div className="panel-heading flex-row flex-between p-v-16 p-h-16">
                        <h2>NLIS Movement Details</h2>
                        {consignment?.movementDate ? (
                            <div className="date-info flex-row ">
                                <DateIcon />
                                <label
                                    htmlFor=""
                                    className="info-label grey-text"
                                >
                                    Movement Date
                                </label>
                                <p className="bold">
                                    {consignment?.movementDate
                                        ? new Date(consignment?.movementDate as any).toMLADateString()
                                        : ''}
                                </p>
                            </div>
                        ) : null}
                    </div>
                    <p className="panel-heading flex-row flex-between p-h-16">
                        If anything is incorrect, please contact {consignment?.owner?.name} to update the information
                    </p>
                    <hr />
                    <div className="p-h-16 p-v-16">
                        <div className="horizontal-line" />
                    </div>
                    <MovingToFromSummary consignment={consignment} />
                </div>
            </div>
        </>
    );
};

export default DevicesMovementToFromSection;
