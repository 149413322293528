/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeviceMovementStatusQueryVariables = {
    consignmentNumber: string;
    envdAccountId: string;
};
export type DeviceMovementStatusQueryResponse = {
    readonly deviceMovementStatus: {
        readonly movementStatus: string;
    } | null;
};
export type DeviceMovementStatusQuery = {
    readonly response: DeviceMovementStatusQueryResponse;
    readonly variables: DeviceMovementStatusQueryVariables;
};



/*
query DeviceMovementStatusQuery(
  $consignmentNumber: String!
  $envdAccountId: String!
) {
  deviceMovementStatus(envdAccountId: $envdAccountId, consignmentNumber: $consignmentNumber) {
    movementStatus
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "consignmentNumber"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envdAccountId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "consignmentNumber",
        "variableName": "consignmentNumber"
      },
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      }
    ],
    "concreteType": "DeviceMovementStatusType",
    "kind": "LinkedField",
    "name": "deviceMovementStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "movementStatus",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceMovementStatusQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceMovementStatusQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3f3e687e498e1919017b3bee4b6ceff7",
    "id": null,
    "metadata": {},
    "name": "DeviceMovementStatusQuery",
    "operationKind": "query",
    "text": "query DeviceMovementStatusQuery(\n  $consignmentNumber: String!\n  $envdAccountId: String!\n) {\n  deviceMovementStatus(envdAccountId: $envdAccountId, consignmentNumber: $consignmentNumber) {\n    movementStatus\n  }\n}\n"
  }
};
})();
(node as any).hash = '39990217adfa4bacdd27d39a376476ea';
export default node;
