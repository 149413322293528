import { MovementActions, useMovementContext } from '@common/context/MovementContext';
import { ContextMenuOption } from '@components/Form/ContextMenu';
import Config from '@config';
import useRoleValidation from '@effects/useRoleValidation';
import { actionTypes, useGlobalDispatch } from '@state';
import { IUser } from '@typings';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { RoleTypeEnum, SectionName } from '@utils/enums';
import { canCreateConsignment, canViewTemplate, isMyConsignment } from '@utils/question-editable';
import { MutableRefObject, useLayoutEffect, useState } from 'react';

import { AddBuyerModalRef } from './components/AddBuyerModal';
import { AddViewerModalRef } from './components/AddViewerModal';
import { ConfirmModalRef } from './components/ConfirmModal';
import { containsDeprecatedForms, fromPriorSystem } from './ConsignmentHelper';

function GetConsignmentContextMenu(
    consignment: {
        status: string | null;
        createdBy: string;
        number: string;
        type: string | null;
        createdAt?: string;
        pdfUrl?: string;
        movementDate: string;
        origin: any;
        owner: any;
        consignee: any;
        destination: any;
        numOfAddedDevices: number;
        movementID?: number;
    },
    user: IUser | undefined,
    deleteModalRef: MutableRefObject<ConfirmModalRef | undefined>,
    printModalRef: MutableRefObject<ConfirmModalRef | undefined> | undefined,
    isOnDetailPage: boolean,
    history: any,
    viewerModalRef?: MutableRefObject<AddViewerModalRef | undefined>,
    buyerModalRef?: MutableRefObject<AddBuyerModalRef | undefined>
) {
    const canCreate = canCreateConsignment(user);
    const canView = canViewTemplate(user);
    const isDeprecated = containsDeprecatedForms(consignment);
    const consignmentPDFurl = consignment?.pdfUrl?.startsWith('http') ? consignment?.pdfUrl : Config.BASE_GRAPHQL_SERVER_URL + '/' + consignment?.pdfUrl;
    const globalDispatch = useGlobalDispatch();

    const [actions, setActions] = useState<MovementActions>();

    const { hasAnyOfRoles, hasRole } = useRoleValidation();

    const { movementActions } = useMovementContext();

    useLayoutEffect(() => {
        if (!consignment || !consignment.number) return;

        const actions = movementActions && movementActions[consignment.number];
        setActions(actions);
    }, [consignment, movementActions]);

    const isTemplate = consignment.type === 'TEMPLATE';
    const templateNotSupported = fromPriorSystem(consignment);
    const consignmentNotSupported = fromPriorSystem(consignment) || containsDeprecatedForms(consignment);
    const hasDevices = Boolean(consignment.numOfAddedDevices > 0);
    const isDraft = consignment.status === ConsignmentStatus.DRAFT.toString();
    const isSubmitted = consignment.status === ConsignmentStatus.SUBMITTED.toString();
    const isLocked = consignment.status === ConsignmentStatus.LOCKED.toString();
    const isNotLocked = !isLocked;
    const isOwnedByUser = isMyConsignment(consignment, user!);
    const isFacilitator = hasRole(RoleTypeEnum.FACILITATOR);
    const isElevatedViewer = hasRole(RoleTypeEnum.ELEVATEDVIEWER);
    const isExpired = hasRole(RoleTypeEnum.EXPIRED);
    const isTransporter = hasRole(RoleTypeEnum.TRANSPORTER);

    const canAccessConsignment = (isDraft && (isOwnedByUser || isElevatedViewer)) ||
        ((isSubmitted || isLocked) && !isExpired);
    const canUpdateConsignment = (isNotLocked && isOwnedByUser) || (isSubmitted && isTransporter);

    const getTemplateMenuItems = (): ContextMenuOption[] => [
        // Create consignment from template
        ...(canCreate && !isDeprecated ? [{
            id: 125,
            title: 'Create new consignment from this template',
            onClick: () => history.push(`/consignments/copy/${consignment.number}`)
        }] : []),

        // Edit/View template based on permissions
        ...(!templateNotSupported ? [
            ...(canCreate && !isDeprecated ? [{
                id: 123,
                title: 'Edit template',
                onClick: () => history.push(`/templates/edit/${consignment.number}/${SectionName.LIVESTOCK_DESCRIPTION}`)
            }] : []),
            ...(!canCreate && canView && !isDeprecated ? [{
                id: 123,
                title: 'View template',
                onClick: () => history.push(`/templates/edit/${consignment.number}/${SectionName.LIVESTOCK_DESCRIPTION}`)
            }] : [])
        ] : []),

        // Delete template
        ...(canCreate ? [{
            id: 127,
            title: 'Delete template',
            className: 'text-error',
            onClick: () => deleteModalRef.current?.show()
        }] : [])
    ];

    const getMovementMenuItems = (): ContextMenuOption[] => {
        if (!actions || !canAccessConsignment) return [];

        return [
            // Preview movement
            ...(actions.canPreviewMovement ? [{
                id: 135,
                title: 'Preview Upcoming Livestock Movement',
                subtitle: 'View your upcoming livestock details.',
                onClick: () => console.log('Previewing transfer')
            }] : []),

            // Create transfer
            ...(actions.canPerformMovement ? [{
                id: 136,
                title: 'Create Livestock Transfer',
                subtitle: 'Transfer onto your property now available.',
                onClick: () => history.push(`/consignments/${consignment.number}/movement`)
            }] : []),

            // View devices
            ...(((!actions.isReceiver && actions.canAddOrEditDevicesForProducer) && hasDevices) ? [{
                id: 137,
                title: 'View NLIS Devices',
                subtitle: 'NLIS devices have been added to this consignment but can still be edited until the movement date has passed',
                onClick: () => history.push(`/consignments/${consignment.number}/movement`)
            }] : []),

            // View devices
            ...(((!actions.isReceiver && !actions.canAddOrEditDevicesForProducer) && hasDevices) ? [{
                id: 137,
                title: 'View NLIS Devices',
                subtitle: 'You can view NLIS devices and track their movement, which will be managed by your recipient',
                onClick: () => history.push(`/consignments/${consignment.number}/movement`)
            }] : []),

            // Add devices
            ...(actions.canAddOrEditDevicesForProducer && !hasDevices ? [{
                id: 139,
                title: 'Add NLIS Devices',
                subtitle: 'You can now provide electronic identification device (eID) details such as NLISID (visual tag ID) or RFID',
                onClick: () => history.push(`/consignments/${consignment.number}/movement`)
            }] : [])
        ];
    };

    const getStandardMenuItems = (): ContextMenuOption[] => [
        // View/update consignment
        ...(!isOnDetailPage && canAccessConsignment ? [{
            id: 123,
            title: canUpdateConsignment && !consignmentNotSupported ? 'View and Update consignment' : 'View Consignment',
            subtitle: canUpdateConsignment
                ? 'View and make changes to this consignment'
                : !consignmentNotSupported
                    ? 'View documents and consignment details.'
                    : 'View all the details within the consignment',
            onClick: () => !consignmentNotSupported
                ? history.push(`/consignments/summary/${consignment.number}`)
                : onDeprecatedConsignmentClick()
        }] : []),

        // Create template
        ...(isOwnedByUser && !consignmentNotSupported ? [{
            id: 126,
            title: 'Create a new template',
            subtitle: 'Is this a frequent consignment? Create a template to make creating future consignments quicker',
            onClick: () => history.push(`/templates/add/${consignment.number}`)
        }] : []),

        // Duplicate consignment
        ...(isOwnedByUser && !consignmentNotSupported ? [{
            id: 125,
            title: 'Duplicate this consignment',
            subtitle: 'This copies the consignment details into a new one, this is great for one off duplicates',
            onClick: () => history.push(`/consignments/copy/${consignment.number}`)
        }] : []),

        // View eNVD
        ...(canAccessConsignment && !consignmentNotSupported ? [{
            id: 130,
            title: 'View eNVD',
            subtitle: getPreviewPdfSubtitle(),
            onClick: () => window.open(consignmentPDFurl, 'allForms')
        }] : []),

        // Add Viewer
        ...(isOwnedByUser && isNotLocked && !consignmentNotSupported ? [{
            id: 134,
            title: 'Add Viewer',
            subtitle: 'Viewers will be able to see this consignment.',
            onClick: () => {
                if (isOnDetailPage) {
                    viewerModalRef?.current?.show();
                } else {
                    history.push(`/consignments/summary/${consignment.number}`);
                    globalDispatch({
                        type: actionTypes.generic.setOptionStateAction,
                        value: { payload: { isScrollToCommentSection: false, isOpenViewersModal: true, isOpenBuyerModal: false } },
                    });
                }
            }
        }] : []),

        // Add Buyer
        ...(isFacilitator && isSubmitted && !consignmentNotSupported ? [{
            id: 135,
            title: 'Add Buyer',
            subtitle: 'Buyers will be able to see this consignment and their livestock bought.',
            onClick: () => {
                if (isOnDetailPage) {
                    buyerModalRef?.current?.show();
                } else {
                    history.push(`/consignments/summary/${consignment.number}`);
                    globalDispatch({
                        type: actionTypes.generic.setOptionStateAction,
                        value: { payload: { isScrollToCommentSection: false, isOpenViewersModal: false, isOpenBuyerModal: true } },
                    });
                }
            }
        }] : []),

        // Add Comment
        ...(!hasAnyOfRoles([RoleTypeEnum.BUYER, RoleTypeEnum.ELEVATEDVIEWER, RoleTypeEnum.EXPIRED]) &&
            isSubmitted && !consignmentNotSupported ? [{
                id: 136,
                title: 'Add Comment',
                subtitle: 'Communicate with other people about this consignment by adding a comment.',
                onClick: () => {
                    if (isOnDetailPage) {
                        window.scrollTo(0, document.body.scrollHeight);
                    } else {
                        history.push(`/consignments/summary/${consignment.number}`);
                        globalDispatch({
                            type: actionTypes.generic.setOptionStateAction,
                            value: { payload: { isOpenViewersModal: false, isScrollToCommentSection: true, isOpenBuyerModal: false } },
                        });
                    }
                }
            }] : []),

        // Delete consignment
        ...(isOwnedByUser && isNotLocked ? [{
            id: 150,
            title: 'Delete consignment',
            className: 'text-error',
            onClick: () => deleteModalRef.current?.show()
        }] : [])
    ];

    // Helper function for PDF subtitle text
    const getPreviewPdfSubtitle = () => {
        const isPdfPreview = consignment.status === ConsignmentStatus.DRAFT.toString() &&
            (isOwnedByUser || hasRole(RoleTypeEnum.ELEVATEDVIEWER));

        if (isPdfPreview) {
            return isOwnedByUser
                ? 'Preview the PDF version of this consignment before you submit the consignment.'
                : 'Preview the PDF version of this draft consignment.';
        }
        return 'Creates a PDF version of this consignment for you to share.';
    };

    const onDeprecatedConsignmentClick = () => {
        if (printModalRef) {
            const { current } = printModalRef;

            if (current) {
                current.show();
            }
        }
    };

    // Combine menu items adn sort them based on id
    return (isTemplate ? getTemplateMenuItems() : [...getStandardMenuItems(), ...getMovementMenuItems()]).sort((a, b) => a.id - b.id);
}

export default GetConsignmentContextMenu;
