import * as React from 'react';
const DeviceArrowRight = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props} viewBox="0 0 20 20">
        <path
            fill="currentColor"
            d="M19.41 9.58L10.41 0.58C10.04 0.21 9.53 0 9 0H2C0.9 0 0 0.9 0 2V9C0 9.53 0.21 10.04 0.59 10.41L1 10.81C1.9 10.27 2.94 10 4 10C7.31 10 10 12.69 10 16C10 17.06 9.72 18.09 9.18 19L9.58 19.4C9.95 19.78 10.47 20 11 20C11.53 20 12.04 19.79 12.41 19.41L19.41 12.41C19.79 12.04 20 11.53 20 11C20 10.47 19.79 9.96 19.41 9.58ZM3.5 5C2.67 5 2 4.33 2 3.5C2 2.67 2.67 2 3.5 2C4.33 2 5 2.67 5 3.5C5 4.33 4.33 5 3.5 5ZM4 15H0V17H4V19L7 16L4 13V15Z"
        />
    </svg>
);
export default DeviceArrowRight;
