import Config from '@config';
import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';
import { getRequest } from 'relay-runtime';
import { IDeviceValidation } from 'src/typings/device';

import { SaveOrUpdateDevicesInput } from './__generated__/saveOrUpdateDevicesMutation.graphql';

export const saveOrUpdateDevicesMutation = graphql`
    mutation saveOrUpdateDevicesMutation($input: SaveOrUpdateDevicesInput!, $envdAccountId: String!) {
        saveOrUpdateDevices(input: $input, envdAccountId: $envdAccountId) {
            data {
                value {
                    nLISID
                    rFID
                    registeredTo
                    species
                    status
                    deceased
                }
                totalDevices
            }
        }
    }
`;

// Helper function to get mutation string
const getMutationString = (node: any) => {
    const request = getRequest(node);
    return request?.params?.text;
};

const commitAsync = async (
    devices: SaveOrUpdateDevicesInput,
    envdAccountId: String,
    token: string
): Promise<Partial<IDeviceValidation>> => {
    MLALogger.Log(['validating devices', 'commitAsync', 'Sending'], { devices });
    try {
        // Using fetch instead of Relay mutation due to performance reasons:
        // 1. Large datasets (2000-10000 devices) cause significant Relay normalization overhead
        // 2. Relay adds 10-15s processing time while API response is only ~2.5s
        // 3. No Relay store needed as we use global context
        const response = await fetch(Config.BASE_GRAPHQL_SERVER_URL + "/graphql", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                query: getMutationString(saveOrUpdateDevicesMutation),
                variables: {
                    input: devices,
                    envdAccountId,
                },
            }),
        });

        const data = await response.json();
        // Log successful response (equivalent to onCompleted)
        MLALogger.Log(['saveOrUpdateDevices', 'commitAsync'], {
            response: data,
            error: data.errors,
        });

        if (data.errors) {
            // Log GraphQL errors
            MLALogger.Log(
                ['saveOrUpdateDevices', 'commitAsync'],
                {
                    error: data.errors,
                },
                'error'
            );
            throw new Error(data.errors[0].message);
        } else {
            // Log successful data
            MLALogger.Log(['saveOrUpdateDevices', 'commitAsync'], {
                response: data,
                data: data.data.saveOrUpdateDevices.data,
                request: devices,
            });
        }
        return data.data.saveOrUpdateDevices.data;
    } catch (error) {
        MLALogger.Log(['saveOrUpdateDevices'], error, 'error');
        throw error;
    }
};

export default commitAsync;
