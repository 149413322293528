import 'jspdf-autotable';

import { ClearIcon, ExportIcon, InfoIcon, RevalidateIcon } from '@assets/icons';
import { MovementActions, useMovementContext } from '@common/context/MovementContext';
import Button from '@components/Button';
import ContextMenu from '@components/Form/ContextMenu';
import FilterMenu from '@components/Form/FilterMenu';
import { IFilterGroupMenuGroup, IFilterMenuOption } from '@components/Form/FilterMenuOptions';
import Input from '@components/Form/Input';
import ToggleSwitch from '@components/Form/ToggleSwitch';
import Tooltip from '@components/Form/Tooltip';
import HoverableIcon from '@components/HoverableIcon';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import { isOptionsDisabled } from '@containers/Consignments/DeviceTransferHelper';
import useDeviceManager from '@effects/useDeviceManager';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface DeviceTableFiltersProps {
    showToggle: boolean;
    filters: {
        showIssuesOnly: boolean;
        isDeceased: boolean | null;
        species: string;
        status: string;
        searchText: string;
    };
    onFilterChange: (filterName: string, value: any) => void;
    consignment: ConsignmentDetailQueryResponse['consignment'];
    onRevalidate: () => void;
    activeDeviceId: string | null
}

const DeviceTableFilters: React.FC<DeviceTableFiltersProps> = ({
    showToggle,
    filters,
    onFilterChange,
    consignment,
    onRevalidate,
    activeDeviceId
}) => {
    const { exportToPDF, exportToCSV, exportToTXT } = useDeviceManager();
    const { movementActions } = useMovementContext();
    const [actions, setActions] = useState<MovementActions>();
    const { showIssuesOnly, isDeceased, searchText } = filters;

    const isTableOptionsDisabled = activeDeviceId != null;
    useEffect(() => {
        if (!movementActions) return;

        if (!consignment || !consignment.number) return;

        const mActions = movementActions && movementActions[consignment.number];
        if (!mActions) return;
        setActions(mActions);
    }, [consignment, movementActions]);

    const [filterGroups, setFilterGroups] = useState<IFilterGroupMenuGroup[]>([
        {
            title: 'Species',
            selectionType: 'single',
            options: [
                { id: 'CATTLE', title: 'Cattle', selected: false },
                { id: 'BOBBY_CALVES', title: 'Bobby Calves', selected: false },
                { id: 'GOAT', title: 'Goats', selected: false },
                { id: 'SHEEP', title: 'Sheep and Lamb', selected: false },
            ],
        },
        {
            title: 'Device Status',
            selectionType: 'single',
            options: [
                { id: 'Active', title: 'Active', selected: false },
                { id: 'Inactive', title: 'Inactive', selected: false },
                { id: 'Not_Found', title: 'Not Found', selected: false },
            ],
        },
        {
            title: 'Deceased',
            selectionType: 'single',
            options: [
                { id: 'YES', title: 'Yes', selected: isDeceased === true },
                { id: 'NO', title: 'No', selected: isDeceased === false },
            ],
        },
    ]);

    const clearFilter = useCallback(
        (menuOption: IFilterMenuOption) => {
            const updatedFilterGroups = filterGroups.map((filterGroup) => ({
                ...filterGroup,
                options: filterGroup.options.map((option) =>
                    option.id === menuOption.id ? { ...option, selected: false } : option
                ),
            }));
            setFilterGroups(updatedFilterGroups);
        },
        [filterGroups]
    );

    const renderSelectedFilters = useMemo(() => {
        const filters = filterGroups
            .flatMap((filterGroup) => filterGroup.options)
            .filter((option) => option.selected)
            .filter((option) => !option.id.includes('custom'))
            .map((option) => (
                <Button
                    key={option.id}
                    action={() => clearFilter(option)}
                    buttonType={isTableOptionsDisabled ? 'tertiary-disable' : 'tertiary'}
                    buttonSize="small"
                    disabled={isTableOptionsDisabled}
                >
                    <ClearIcon />
                    {option.title}
                </Button>
            ));

        return filters;
    }, [filterGroups, isTableOptionsDisabled, clearFilter]);

    useEffect(() => {
        const speciesGroup = filterGroups[0];
        if (speciesGroup.options.some((option) => option.selected)) {
            onFilterChange('species', speciesGroup.options.find((option) => option.selected)?.id || '');
        } else {
            onFilterChange('species', '');
        }

        const statusGroup = filterGroups[1];
        if (statusGroup.options.some((option) => option.selected)) {
            onFilterChange('status', statusGroup.options.find((option) => option.selected)?.id || '');
        } else {
            onFilterChange('status', '');
        }

        const deceasedGroup = filterGroups[2];
        if (deceasedGroup.options.some((option) => option.selected)) {
            const selectedId = deceasedGroup.options.find((o) => o.selected)?.id;
            onFilterChange('isDeceased', selectedId === 'YES');
        } else {
            onFilterChange('isDeceased', null);
        }
    }, [filterGroups, onFilterChange]);

    const resetFilters = useCallback(() => {
        const updatedfilterGroups = filterGroups.map((filterGroup) => ({
            ...filterGroup,
            options: filterGroup.options.map((option) => ({ ...option, selected: false })),
        }));
        setFilterGroups(updatedfilterGroups);
        onFilterChange('searchText', '');
        onFilterChange('species', '');
        onFilterChange('status', '');
        onFilterChange('showIssuesOnly', false);
    }, [filterGroups, onFilterChange]);

    const consignmentNumber = consignment?.number as string;

    const exportOptions = () => {
        return [
            {
                id: 1,
                title: 'PDF',
                subtitle: '',
                onClick: async () => {
                    exportToPDF(consignmentNumber);
                },
            },
            {
                id: 2,
                title: 'CSV',
                subtitle: '',
                onClick: () => {
                    exportToCSV(consignmentNumber);
                },
            },
            {
                id: 3,
                title: 'TXT',
                subtitle: '',
                onClick: () => {
                    exportToTXT(consignmentNumber);
                },
            },
        ].coalesce();
    };

    return (
        <>
            {searchText && <p className="midbold p-v-8 results-for">Showing results for {searchText}</p>}

            <div className="flex-start-row flex-between p-t-8">
                <div className="button-group flex-row ">
                    <span className="m-h-4">
                        <Button
                            buttonType={isOptionsDisabled(actions!) || isTableOptionsDisabled ? 'tertiary-disable' : 'tertiary'}
                            onClick={() => onRevalidate()}
                            disabled={isOptionsDisabled(actions!) || isTableOptionsDisabled}
                        >
                            <RevalidateIcon />
                            <span>Revalidate</span>
                        </Button>
                    </span>
                    <span className="m-h-4">
                        <ContextMenu
                            options={exportOptions()}
                            prefixIcon={<ExportIcon />}
                            buttonSize="normal"
                            buttonText="Export"
                            buttonDisabled={isTableOptionsDisabled}
                        />
                    </span>
                    <span className="m-h-4">
                        <FilterMenu
                            title="Filter by"
                            groups={filterGroups}
                            onUpdate={(groups) => setFilterGroups(groups)}
                            buttonType="tertiary"
                            isDisabled={isTableOptionsDisabled}
                        />
                    </span>
                    {renderSelectedFilters}
                    {renderSelectedFilters.length > 0 && (
                        <Button
                            buttonType={isTableOptionsDisabled ? "tertiary-disable" : "link"}
                            onClick={resetFilters}
                            buttonSize="small"
                            disabled={isTableOptionsDisabled}
                        >
                            Clear filter{renderSelectedFilters.length > 1 ? 's' : ''}
                        </Button>
                    )}
                </div>
                <div>
                    <Input
                        value={searchText}
                        onChange={(e) => onFilterChange('searchText', e.target.value)}
                        maxLength={20}
                        placeholder="Search by NLISID or RFID"
                        style={{ width: '360px' }}
                        disabled={isTableOptionsDisabled}
                    />
                </div>
            </div>

            {showToggle && (
                <div className="m-b-20">
                    <div className="align-items-center">
                        <span className="midbold p-h-8">Show issues only</span>
                        <span style={{ position: 'relative', top: '8px' }}>
                            <Tooltip
                                placement="top"
                                text="Enable 'show issues only' to display tags with issues. To view all tags again, simply turn off this option."
                            >
                                <HoverableIcon
                                    Icon={InfoIcon}
                                    defaultColor="#627CA2"
                                    hoverColor="#436290"
                                />
                            </Tooltip>
                        </span>
                        <span
                            className="p-h-16"
                            style={{ position: 'relative', top: '-4px' }}
                        >
                            <ToggleSwitch
                                disabled={isTableOptionsDisabled}
                                checked={showIssuesOnly}
                                onChange={(e) => onFilterChange('showIssuesOnly', e.target.checked)}
                            />
                        </span>
                    </div>
                </div>
            )}

            <style jsx>{`
                @import 'utils';

                .button-group {
                    gap: grid(2);
                }
            `}</style>
        </>
    );
};

export default DeviceTableFilters;
