import * as React from 'react';
const DeviceMinusIcon = ({
    color = "#333ADD",
    width = 24,
    height = 24,
    ...props
}) => (
    <svg xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={{
            width: `${width}px`,
            height: `${height}px`,
            ...props.style
        }}
        viewBox="0 0 30 30"
        {...props}
    >
        <path
            fill={color}
            d="M29.115 14.37 15.615.87A3 3 0 0 0 13.5 0H3a3 3 0 0 0-3 3v10.5c0 .795.315 1.56.885 2.115l.615.6C2.85 15.405 4.41 15 6 15a9 9 0 0 1 9 9c0 1.59-.42 3.135-1.23 4.5l.6.6c.555.57 1.335.9 2.13.9s1.56-.315 2.115-.885l10.5-10.5c.57-.555.885-1.32.885-2.115a3 3 0 0 0-.885-2.13M5.25 7.5a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5m6.75 18H0v-3h12z"
        />
    </svg>
);
export default DeviceMinusIcon;
