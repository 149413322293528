import React from 'react';

const NLISSupport: React.FC = () => (
    <a
        href="https://www.integritysystems.com.au/identification--traceability/technical-support/"
        target="_blank"
        rel="noopener noreferrer"
    >
        NLIS Support
    </a>
);

export default NLISSupport;
