import { CsvFileIcon, DeleteIcon, ResetIcon, TxtFileIcon, UploadIcon, WarningIcon } from '@assets/icons';
import Button from '@components/Button';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import useDeviceManager from '@effects/useDeviceManager';
import { useWindowWidth } from '@effects/useWindowWidth';
import { CSV_FILE_TYPE, MAX_UPLOAD_DEVICES, TXT_FILE_TYPE } from '@utils/constants';
import classNames from 'classnames';
import React, { ReactNode, useRef } from 'react';
import Dropzone from 'react-dropzone';

interface DragAndDropProps {
    children?: ReactNode;
}
const DragDropUpload: React.FunctionComponent<DragAndDropProps> = ({ children }) => {
    const confirmSubmitRef = useRef<ConfirmModalRef>();
    const {
        dropzoneRef,
        openDialog,
        inDropZone,
        acceptedFile,
        uploadErrorMessage,
        onDragLeave,
        onDragOver,
        onDropAccepted,
        onDropRejected,
        processFile,
        clearFiles,
    } = useDeviceManager();
    const width = useWindowWidth();
    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';

                    .upload-container {
                        width: 70%;
                        min-width: 300px;
                    }

                    .drag-drop {
                        border: 2px dashed $color-grey47;
                        border-radius: 8px;
                        width: 100%;
                        height: auto;
                        padding: 20px;

                        &--Active {
                            border: 2px dashed $isc-blue;
                            background: $light-blue;
                        }
                        &--Reject {
                            border-color: $color-error-hover;
                            color: $color-error;
                            div {
                                p {
                                    color: $color-error;
                                }
                            }
                        }
                        &--Disable {
                            div {
                                p {
                                    color: $color-grey47;
                                }
                            }
                        }
                    }

                    .uploadIcon {
                        color: $color-link;
                        &--Reject {
                            color: $color-error;
                        }
                        &--Disable {
                            color: $color-grey47;
                        }
                    }

                    .acceptedFiles {
                        padding: 8px 15px;
                        border: 1px solid $color-line;
                        border-radius: 5px;

                        .fileIcon {
                            color: $color-link;
                            cursor: pointer;
                        }

                        .fileName {
                            margin: 0;
                        }

                        .fileName:nth-child(1) {
                            font-weight: 700;
                        }

                        .fileName:nth-child(2) {
                            color: $color-grey47;
                        }
                    }
                    .warning-icon {
                        :global(svg) {
                            width: $icon-lg;
                            height: $icon-lg;
                            color: $color-error;
                        }
                    }

                    .gap {
                        gap: 10px;
                        font-size: 16px;
                    }

                    .document-uploader {
                        padding: 0px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        border-radius: 8px;
                        cursor: pointer;

                        .upload-info {
                            display: flex;
                            align-items: center;
                            margin-bottom: 1rem;

                            div {
                                p {
                                    margin: 0;
                                    font-size: 16px;
                                    text-align: center;
                                }
                                p:nth-child(2) {
                                    font-weight: bold;
                                }
                                p:nth-child(3) {
                                    margin-top: 20px;
                                }
                            }
                        }

                        input[type='file'] {
                            display: none;
                        }
                    }
                `}
            </style>
            <>
                <div
                    className={`${width > 740 ? 'flex-start-row' : 'flex-center-column'} m-v-32`}
                    style={{ width: '100%', gap: 15 }}
                >
                    {children}
                    <Dropzone
                        noDrag={acceptedFile?.length > 0}
                        maxFiles={1}
                        ref={dropzoneRef}
                        noClick
                        noKeyboard
                        onDropAccepted={onDropAccepted}
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={processFile}
                        accept={{
                            'text/plain': ['.txt'],
                            'text/csv': ['.csv'],
                        }}
                        onDropRejected={onDropRejected}
                    >
                        {({ getRootProps, getInputProps, isDragReject }) => {
                            return (
                                <div style={{ width: '100%' }}>
                                    <div
                                        {...getRootProps({ className: 'dropzone' })}
                                        style={{ width: '100%' }}
                                    >
                                        <section
                                            className={classNames('drag-drop', {
                                                'drag-drop--Active': inDropZone,
                                                'drag-drop--Reject': uploadErrorMessage.length > 0,
                                                'drag-drop--Disable':
                                                    acceptedFile?.length > 0 && uploadErrorMessage.length === 0,
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="document-uploader">
                                                <div className="upload-info">
                                                    <div className="flex-center-column">
                                                        <div
                                                            className={`m-v-12 ${classNames('uploadIcon', {
                                                                'uploadIcon--Reject': uploadErrorMessage.length > 0,
                                                                'uploadIcon--Disable':
                                                                    acceptedFile?.length > 0 &&
                                                                    uploadErrorMessage.length === 0,
                                                            })} `}
                                                        >
                                                            <UploadIcon style={{ width: 37 }} />
                                                        </div>
                                                        <p>Upload a file or drag and drop</p>
                                                        <p>Accepted formats: .CSV, .TXT</p>
                                                        <p>You can upload only 1 file</p>
                                                        <p>{`File must not exceed ${MAX_UPLOAD_DEVICES.toLocaleString()} Device IDs`}</p>
                                                    </div>
                                                </div>
                                                <Button
                                                    buttonType={
                                                        acceptedFile.length > 0 && uploadErrorMessage.length === 0
                                                            ? 'disable'
                                                            : uploadErrorMessage.length > 0
                                                            ? 'delete'
                                                            : 'primary'
                                                    }
                                                    buttonSize="small"
                                                    onClick={openDialog}
                                                    className="browse-btn"
                                                >
                                                    Choose a file
                                                </Button>
                                            </div>
                                        </section>

                                        {uploadErrorMessage.length > 0 && (
                                            <p className="drag-drop--Reject m-v-12">Error: {uploadErrorMessage}</p>
                                        )}
                                    </div>
                                </div>
                            );
                        }}
                    </Dropzone>
                </div>
                <aside>
                    {uploadErrorMessage.length === 0 && (
                        <>
                            {acceptedFile?.map((file, key) => (
                                <div
                                    key={key}
                                    className="acceptedFiles flex-center-row flex-between m-v-12"
                                >
                                    <div className="flex-center-row">
                                        <div>
                                            {file.type === CSV_FILE_TYPE && (
                                                <CsvFileIcon style={{ width: 32, height: 32 }} />
                                            )}
                                            {file.type === TXT_FILE_TYPE && (
                                                <TxtFileIcon style={{ width: 32, height: 32 }} />
                                            )}
                                        </div>
                                        <div className="flex-start-column p-h-12">
                                            <p className="fileName small">{file.name}</p>
                                            <p className="fileName small">
                                                {file.name.split('.').pop()?.toUpperCase()} .{' '}
                                                {(file.size / 1024).toFixed(0) + 'KB'}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="fileIcon"
                                        onClick={clearFiles}
                                    >
                                        <DeleteIcon />
                                    </div>
                                </div>
                            ))}
                            {acceptedFile?.length > 0 && (
                                <Button
                                    buttonType="reset"
                                    buttonSize="small"
                                    onClick={() => confirmSubmitRef.current?.show()}
                                >
                                    <div className="flex-center-row flex-between gap">
                                        Reset
                                        <ResetIcon />
                                    </div>
                                </Button>
                            )}
                        </>
                    )}
                </aside>
                <ConfirmModal
                    actions={[
                        {
                            style: 'secondary',
                            text: 'Cancel',
                            buttonSize: 'full-width',
                            action: async () => {
                                confirmSubmitRef.current?.hide();
                            },
                        },
                        {
                            style: 'delete',
                            text: 'Reset',
                            buttonSize: 'full-width',
                            action: async () => {
                                clearFiles();
                                confirmSubmitRef.current?.hide();
                            },
                        },
                    ]}
                    ref={confirmSubmitRef}
                    modalId={`confirm-modal`}
                    title={
                        <div className="flex-center-row warning-icon gap">
                            <WarningIcon /> Reset
                        </div>
                    }
                >
                    <p>
                        Reset will remove all current devices from this consignment, you will no longer be able to view
                        it on your device or web.{' '}
                    </p>
                    <p>Are you sure you want to proceed with this action?</p>
                </ConfirmModal>
            </>
        </>
    );
};

export default DragDropUpload;
